import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import PermMediaOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActual';
import PublicIcon from '@mui/icons-material/Public';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import TimerIcon from '@mui/icons-material/Timer';
import SettingsIcon from '@mui/icons-material/Settings';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import logo from "../pics/logo.png";

const categories = [
  {
    id: 'Menu',
    children: [
      // { id: 'FDD TRIGGER',icon: <PublicIcon />,},
      { id: 'FDD LOGS', icon: <PeopleIcon />,},
      { id: 'DOWNL0AD RESULTS', icon: <DnsRoundedIcon /> },
      // { id: 'S3 DATA', icon: <PermMediaOutlinedIcon /> },
      // { id: 'Pipeline Update', icon: <PublicIcon /> },
      // { id: 'Resources', icon: <SettingsEthernetIcon /> },
    ],
  }
];

const item = {
  py: '12px',
  px: 1,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 5,
  px: 3,
};

export default function Navigator(props) {
  const { ...other } = props;
  console.log("props", props)
  console.log("{...props} ", { ...props })

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <img src={logo} alt="Logo" style={{ width: '190px', height: 'auto', position: 'relative', left: '30px', top: '20px' }} />
        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: '#101F33', mt:"4rem"}}>
            {children.map(({ id: childId, icon, active }) => (
              <ListItem disablePadding key={childId}>
                <ListItemButton selected={active} sx={item} onClick={() => props.onNavigate(childId)}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
            {/* <Divider sx={{ mt: 3, mb: 3 }} /> */}
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
